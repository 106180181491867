import React, { useRef } from 'react'
import Home from './pages/home'
import screen2 from './pages/level1'
import screen3 from './pages/screen4'
import Lavel2 from './pages/level2'
import Lavel4 from './pages/level3'
import Lavel5 from './pages/level4'
import Lavel6 from './pages/level5'
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
export default function App() {

    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/level-1" component={screen2} />
                    <Route exact path="/screen3" component={screen3} />
                    <Route exact path="/level-2" component={Lavel2} />
                    <Route exact path="/level-3" component={Lavel4} />
                    <Route exact path="/level-4" component={Lavel5} />
                    <Route exact path="/level-5" component={Lavel6} />
                </Switch>
            </Router>
        </>
    )
}

