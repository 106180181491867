import React from 'react'
import { icon, background } from './json'
import { motion_number } from './fb.net'
export default function Home() {
    let x = JSON.parse(sessionStorage.getItem("screen"))
    let count = false;
    const [viddolenth, setvideolenth] = React.useState(0)
    const [img, setimg] = React.useState("")
    const [awesome, setawesome] = React.useState("")
    const [youtube2, setyoutube2] = React.useState("")
    const [thumb, setthumb] = React.useState("")
    const [points, setpoints] = React.useState(0)
    let box = 0.215645656
    let n = 0.3

    const rendomnumber = () => {
        let x = box
        if (n > x) {
            mouseeve()
        }
    }

    React.useEffect(() => {
        if (!count) {
            count = true
            localStorage.setItem("status", "Horse Dream")
            localStorage.setItem("current", "Puppy Dream")
            setvideolenth('../images/Dog-Wake_00000.png')
            var vid = document.getElementById("Video");
            setTimeout(function () { vid.play() }, 2000);
            const complete = document.getElementById("complete");
            setTimeout(function () { complete.play() }, 2000);
            vid.ontimeupdate = function () { timeexit() };
            // vid.playbackRate = 4.0;
            const audioE2 = document.getElementById("audio2");
            const audioEl = document.getElementById("audio3");
            const audioE3 = document.getElementById("audio");
            if (x.sound == true) {
                vid.muted = x.sound;
                audioE2.muted = x.sound;
                // audioE3.muted = x.sound;
                audioEl.muted = x.sound;
            }
        }
        const video = document.getElementById("Video");
    }, [points])





    // window.addEventListener('mousemove', function (e) {
    const mouseeve = () => {
        const video = document.getElementById("Video");
        const audioE2 = document.getElementById("audio3");
        const audioE3 = document.getElementById("audio2");
        const complete = document.getElementById("complete");
        const background = document.getElementById("background");
        const loder = document.getElementById("loder");
        const keepstill = document.getElementById("keepstill");

        if (video.currentTime > 1) {
            if ((video.currentTime - 5) <= video.duration) {
                setvideolenth('../images/Dog-Wake_00001.png')
                background.classList.remove('animate')
                // loder.classList.remove('loderset')
                audioE2.currentTime = 0
                audioE3.currentTime = 0
                // video.currentTime = 0;
                complete.currentTime = 0;
                complete.pause()
                audioE2.pause()
                audioE3.pause()
                video.pause()
                keepstill.play()
                setawesome('')
                setthumb('')
                document.getElementById("gif").style.display = "none"
                setyoutube2('')
                setTimeout(function () { playvideo() }, 2000);
                video.ontimeupdate = function () { timeexit() };
            } else {
                // video.pause()
            }
        }
    }


    const playvideo = () => {
        const video = document.getElementById("Video");
        video.play();
        const complete = document.getElementById("complete");
        complete.play()
        const background = document.getElementById("background");
        background.classList.add('animate')
        const loder = document.getElementById("loder");
        loder.classList.add('loderset')
        setvideolenth('../images/Dog-Wake_00000.png')
    }

    const imgchange = () => {
        const background = document.getElementById("background");
        const loder = document.getElementById("loder");
        loder.classList.remove('loderset')
        background.classList.remove('animate')
        const awesomesong = document.getElementById("awesomesg");
        const aoye = document.getElementById("aoye");
        setvideolenth('')
        aoye.play()
        setTimeout(function () {
            setawesome('../images/awesome.jpg')
            awesomesong.play()
        }, 4000);
        setTimeout(function () { setthumb("../images/thumb.png") }, 6000)
        setTimeout(function () { setimg("../images/score.png") }, 10000);
        setTimeout(function () { setyoutube2('../images/youtube.png') }, 8000);
        setTimeout(function () { window.location.replace("/screen3") }, 12000);
    }

    const timeexit = () => {
        const video = document.getElementById("Video");
        video.onended = function () {
            document.getElementById("gif").style.display = "block"
            imgchange()
            video.pause()
            let newdat = { ...x, "level1": true }
            sessionStorage.setItem("screen", JSON.stringify(newdat))
            localStorage.setItem("status", "Flight School")
            localStorage.setItem("current", "Horse Dream")
        };
        let xv = video.duration;
        if (video.currentTime >= 193 && video.currentTime <= 194) {
            const audioEl = document.getElementById("audio3");
            audioEl.play();
        }

        else if (video.currentTime >= xv) {
            const audioEl = document.getElementById("audio2");
            audioEl.play();
            const complete = document.getElementById("complete");
            complete.pause()
        }
        else if (video.currentTime >= 184 && video.currentTime <= 208) {
            setTimeout(function () { setpoints(points + 20) }, 9600);
        }
        else if (video.currentTime >= 215 && video.currentTime <= 230) {
            setTimeout(function () { setpoints(points + 20) }, 11000);
        }
        console.log(video.currentTime)
    }
    return (
        <>
            <div class="loderset" id="loder">
                <div class="loder">
                    <span style={{ "--i": 1 }}></span>
                    <span style={{ "--i": 2 }}></span>
                    <span style={{ "--i": 3 }}></span>
                    <span style={{ "--i": 4 }}></span>
                    <span style={{ "--i": 5 }}></span>
                    <span style={{ "--i": 6 }}></span>
                    <span style={{ "--i": 7 }}></span>
                    <span style={{ "--i": 8 }}></span>
                    <span style={{ "--i": 9 }}></span>
                    <span style={{ "--i": 10 }}></span>
                    <span style={{ "--i": 11 }}></span>
                    <span style={{ "--i": 12 }}></span>
                    <span style={{ "--i": 13 }}></span>
                    <span style={{ "--i": 14 }}></span>
                    <span style={{ "--i": 15 }}></span>
                    <span style={{ "--i": 16 }}></span>
                    <span style={{ "--i": 17 }}></span>
                    <span style={{ "--i": 18 }}></span>
                    <span style={{ "--i": 19 }}></span>
                    <span style={{ "--i": 20 }}></span>
                </div>
            </div>
            <div class="right">
                {/* <h2>Background</h2> */}
                {
                    background && background.map((background2) => {
                        if (x.background) {
                            if (background2.screen == x.background) {
                                return (
                                    <img src={background2.background} id="opcity" className="fix" />
                                )
                            }
                        } else if (background2.screen == 1) {
                            return (
                                <img src={background2.background} id="opcity" className="fix" />
                            )
                        }
                    })
                }
            </div>
            <div className=" animate" id="background">
                <audio className="audio-element" id="complete">
                    <source src="../images/Ambient Meditation Music-With SoundFX.mp3" type="audio/mp3" ></source>
                </audio>
                <audio className="audio-element" id="aoye">
                    {/* <source src="../images/Ah Yea.mp3" type="audio/mp3" ></source> */}
                </audio>
                <audio className="audio-element" id="awesomesg">
                    {/* <source src="../images/Amazing.mp3" type="audio/mp3" ></source> */}
                </audio>
                <audio className="audio-element" id="keepstill">
                    {/* <source src="../images/Keep still and watch the dreams unfold.mp3" type="audio/mp3" ></source> */}
                </audio>
                <audio className="audio-element" id="audio2">
                    {/* <source src="../images/last.mp3" type="audio/mp3" ></source> */}

                </audio>
                {/* <audio className="audio-element" id="audio">
                    <source src="../images/fiest.mp3" type="audio/mp3" ></source>
                </audio> */}
                <audio className="audio-element" id="audio3">
                    {/* <source src="../images/second.mp3" type="audio/mp3" ></source> */}
                </audio>

                <div className="video">
                    <video width="65%" id="Video" >
                        <source src="../images/puppy-video.mp4" type="video/mp4" />
                    </video>
                </div>

                <div class="conatiner">
                    <div class="left pointavtar">
                        {/* <h2>Avatar</h2> */}
                        {
                            icon && icon.map((e) => {
                                if (x.icon) {
                                    if (e.screen == x.icon) {
                                        return (
                                            <img src={e.icon} className="" />
                                        )
                                    }
                                }
                                else if (e.screen == 1) {
                                    return (
                                        <img src={e.icon} className="" />
                                    )
                                }
                            })
                        }
                    </div>
                    <span className="points">Points : {points}</span>
                    <span className="box" id="gif">{img == "" ? <img src="../images/elf-unboxing.gif" /> : ""}</span>
                    <span className="awesomespan">{awesome == "" ? "" : <img src="../images/awesome.jpg" />}</span>
                    <span className="awesomespan">{thumb == "" ? "" : <img src="../images/thumb.jpg" />}</span>
                    <span className="awesomespan">{youtube2 == "" ? "" : ""}</span>
                    {/* <span className="box post" id="gif">{img == "" ? "" : <img src={img} />}</span> */}
                </div>
                <div class="puppy">
                    <span class="first"></span>
                    <span class="second"></span>
                    <span class="third"></span>
                    <img src={viddolenth} />
                </div>
            </div>
            <button onClick={e => rendomnumber()}>Demo</button>


        </>
    )
}