// import screen1 from '../../MRI-Avatar (1)'



export const icon = [
    { screen: 1, icon: "../images/MRI-Avatar-(1).png" },
    { screen: 2, icon: "../images/MRI-Avatar (2).png" },
    { screen: 3, icon: "../images/MRI-Avatar (3).png" },
    { screen: 4, icon: "../images/MRI-Avatar (4).png" },
    { screen: 5, icon: "../images/MRI-Avatar (5).png" },
    { screen: 6, icon: "../images/MRI-Avatar (6).png" },
    { screen: 7, icon: "../images/MRI-Avatar (7).png" },
    { screen: 8, icon: "../images/MRI-Avatar (8).png" },
    { screen: 9, icon: "../images/MRI-Avatar (9).png" },
    { screen: 10, icon: "../images/MRI-Avatar (10).png" },
    { screen: 11, icon: "../images/MRI-Avatar (11).png" },
    { screen: 12, icon: "../images/MRI-Avatar (12).png" },
    { screen: 13, icon: "../images/MRI-Avatar (13).png" },
    { screen: 14, icon: "../images/MRI-Avatar (14).png" },
    { screen: 15, icon: "../images/MRI-Avatar (15).png" },
    { screen: 16, icon: "../images/MRI-Avatar (16).png" },
    { screen: 17, icon: "../images/MRI-Avatar (17).png" },
    { screen: 18, icon: "../images/MRI-Avatar (18).png" },
    { screen: 19, icon: "../images/MRI-Avatar (19).png" },
    { screen: 20, icon: "../images/MRI-Avatar (20).png" },
    { screen: 21, icon: "../images/MRI-Avatar (21).png" },
    { screen: 22, icon: "../images/MRI-Avatar (22).png" },
    { screen: 23, icon: "../images/MRI-Avatar (23).png" },
    { screen: 24, icon: "../images/MRI-Avatar (24).png" },
    { screen: 25, icon: "../images/MRI-Avatar (25).png" },
];
export const background = [
    { screen: 1, background: "../gif/Parachute game.jpg" },
    { screen: 2, background: "../gif/Play balls.jpg" },
    { screen: 3, background: "../gif/Game on.jpg" },
    { screen: 4, background: "../gif/Starfish blue.jpg" },
    { screen: 5, background: "../gif/Relax in Space.jpg" },
    { screen: 6, background: "../gif/Pug.jpg" },
    { screen: 7, background: "../gif/Lake view.jpg" },
    { screen: 8, background: "../gif/Blue Beach.jpg" },
    { screen: 9, background: "../gif/Forget me nots.jpg" },
    { screen: 10, background: "../gif/Kitten and Puppy.jpg" },
    { screen: 11, background: "../gif/Northern Lights.jpg" },
    { screen: 12, background: "../gif/Funny Puppy.jpg" },
];