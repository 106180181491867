import React from 'react'
import { Link } from "react-router-dom";
import { icon, background } from './json'

export default function Home() {
    let x = JSON.parse(sessionStorage.getItem("screen"))
    let path1 = window.location.origin + "/./images/level01.png";
    let path2 = window.location.origin + "/./images/Level02.png";
    let path3 = window.location.origin + "/./images/Level03.png";
    let path4 = window.location.origin + "/./images/Level04.png";
    let path5 = window.location.origin + "/./images/Level05.png";
    let path6 = window.location.origin + "/./images/Level06.png";
    let path7 = window.location.origin + "/./images/All-Level-Done.png";
    let count = false;
    const [img, setimg] = React.useState("")
    const [img2, setimg2] = React.useState("")
    const [seconds, setseconds] = React.useState(0)
    const [minutes, setminutes] = React.useState(0)

    setTimeout(function () {
        let showlevel = document.getElementById("lavelshos")
        if (x.level1 == undefined) {
            setimg2(path1);
            setTimeout(function () { window.location.replace("/level-1") }, 5000);
            showlevel.play()
        } else if (x.level5 == true) {
            setimg2(path7);
            let ad = document.getElementById("alldone")
            ad.play()
        } else if (x.level4 == true) {
            setimg2(path5);
            setTimeout(function () { window.location.replace("/level-5") }, 5000);
            showlevel.play()
        } else if (x.level3 == true) {
            setimg2(path4);
            showlevel.play()
            setTimeout(function () { window.location.replace("/level-4") }, 5000);
        } else if (x.level2 == true) {
            setimg2(path3);
            showlevel.play()
            setTimeout(function () { window.location.replace("/level-3") }, 5000);
        }
        else {
            setimg2(path2);
            showlevel.play()
            setTimeout(function () { window.location.replace("/level-2") }, 5000);
        }

        function myFunction() {
            var element = document.getElementById("opcity");
            element.classList.add("opcity");
        }
        myFunction()
    }, 5000);
    // setTimeout(function () { setimg(path2) }, 13000);



    React.useEffect(() => {
    }, [img])

    const checkbox = (e) => {
        let x = JSON.parse(sessionStorage.getItem("screen"))
        if (x == null) {
            sessionStorage.setItem("screen", JSON.stringify({ [e.target.name]: e.target.checked }))
        } else {
            let y = JSON.stringify({ ...x, [e.target.name]: e.target.checked })
            sessionStorage.setItem("screen", y)
        }
    }



    return (
        <>
            <div class="container-fluid bgt2" id="">
                <div class="check_select">
                    <div class="conatiner">
                        <div class="left">
                            {/* <h2>Avatar</h2> */}
                            {
                                icon && icon.map((e) => {
                                    if (x.icon) {
                                        if (e.screen == x.icon) {
                                            return (
                                                <img src={e.icon} className="" />
                                            )
                                        }
                                    }
                                    else if (e.screen == 1) {
                                        return (
                                            <img src={e.icon} className="" />
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div class="right">
                        {/* <h2>Background</h2> */}
                        {
                            background && background.map((background2) => {
                                if (x.background) {
                                    if (background2.screen == x.background) {
                                        return (
                                            <img src={background2.background} id="opcity" className="fix" />
                                        )
                                    }
                                } else if (background2.screen == 1) {
                                    return (
                                        <img src={background2.background} id="opcity" className="fix" />
                                    )
                                }
                            })
                        }

                        <div class="container">
                            <audio className="audio-element" id="alldone">
                                <source src="../images/All Done.mp3" type="audio/mp3" ></source>
                            </audio>
                            <audio className="audio-element" id="lavelshos">
                                <source src="../images/lavelshos.mp3" type="audio/mp3" ></source>
                            </audio>
                            <span><Link className="button">READY?!</Link></span>
                            <span>{img == "" ? <img src={img2} /> : ''}</span>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}