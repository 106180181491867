import React from 'react'
import { Link } from "react-router-dom";
import { icon, background } from './json'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Home() {
    let local = JSON.parse(sessionStorage.getItem("screen"))
    let count = false;
    const [shoavatar, setshoavatar] = React.useState(5);
    const [seconds, setseconds] = React.useState(0)
    const [minutes, setminutes] = React.useState("00:00")
    const [check2, setcheck2] = React.useState([])
    const [back, setback] = React.useState([])
    const [avtar, setavtar] = React.useState([])
    const [progress, setprogress] = React.useState(0)
    const [status, setstatus] = React.useState('')
    const [current, setcurrent] = React.useState('')



    const imagecheck = (e, number) => {
        console.log(e, number)
        let x = JSON.parse(sessionStorage.getItem("screen"))
        if (x == null) {
            sessionStorage.setItem("screen", JSON.stringify({ [e]: number }))
        } else {
            let y = JSON.stringify({ ...x, [e]: number })
            sessionStorage.setItem("screen", y)
        }
    }
    React.useEffect(() => {
        if (!count) {
            let box = document.getElementById("checkbox");
            if (local != null) {
                if (local.sound == true) {
                    box.checked = local.sound
                }
            }
            count = true
        }
    }, [shoavatar])

    const checkbox = (e) => {
        let x = JSON.parse(sessionStorage.getItem("screen"))
        if (x == null) {
            sessionStorage.setItem("screen", JSON.stringify({ [e.target.name]: e.target.checked }))
        } else {
            let y = JSON.stringify({ ...x, [e.target.name]: e.target.checked })
            sessionStorage.setItem("screen", y)
        }
    }

    const checke = () => {
        let x = JSON.parse(sessionStorage.getItem("screen"))
        if (x == null) {
            alert("please select screen")
        } else {
            // window.location.replace("/screen1");
            timestart()
        }
    }


    function startTimer(duration) {
        var timer = duration, minutes, seconds;

        setInterval(function () {
            let get = localStorage.getItem("status")
            let curn = localStorage.getItem("current")
            if (get || curn) {
                setstatus(get)
                setcurrent(curn)
            }

            if (seconds == 0 && minutes == 0) {
                window.location.reload(false)
            } else {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);

                if (minutes < 2) {
                    setprogress(minutes)
                }
                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;
                setseconds(seconds)
                setminutes(minutes)
                if (--timer < 0) {
                    timer = duration;
                }
            }
        }, 963);

    }

    const timestart = () => {
        if (minutes > 0) {
            setprogress(minutes)
            var fiveMinutes = minutes * 60
            startTimer(fiveMinutes);
            window.open("/screen3", "_blank")
        } else {
            alert('select time')
        }

    };
    const classactive = (e) => {
        let x = document.getElementById(e)
        check2.push(e)
        for (var y of check2) {
            let z = document.getElementById(y)
            z.classList.remove('active')
            x.classList.add('active')
        }
    }
    const backactive = (e) => {
        let x = document.getElementById(e)
        back.push(e)
        for (var y of back) {
            let z = document.getElementById(y)
            z.classList.remove('active')
            x.classList.add('active')
        }
    }
    const avtaractive = (e) => {
        let x = document.getElementById(e)
        avtar.push(e)
        for (var y of avtar) {
            let z = document.getElementById(y)
            z.classList.remove('active')
            x.classList.add('active')
        }
    }

    return (
        <>
            <div class="container-fluid bgt">
                <div class="container">
                    <div class="top">
                        <a href="/">Launch Pad </a>
                        <a onClick={e => checke()} target="_blank" class="downcolor">Patient View</a>
                    </div>
                    <div className="rightoperator">
                        <img src="../images/ss.png"></img>
                    </div>
                </div>
            </div>


            <div class="container-fluid bgt2">
                <div class="container">
                    <div class="background">

                        <div class="right">
                            <OwlCarousel
                                className="owl-theme"
                                loop={false}
                                margin={20}
                                nav
                                items={5}
                                navText={['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>']}
                            >
                                {
                                    background && background.map((icon, index) => {
                                        return (
                                            <div class="item"><button onClick={(e) => {
                                                imagecheck("background", index + 1)
                                                backactive("backimg" + index + 1)
                                            }} id={"backimg" + index + 1}><img src={icon.background} alt="" /></button><span>{index + 1}</span></div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                        </div>
                        <div class="left">
                            <label>Enter Number : </label>
                            <input type="test" onChange={(e) => imagecheck("background", e.target.value)} />
                        </div>
                    </div>
                    <div class=" icon">

                        <div class="right">
                            <OwlCarousel
                                className="owl-theme"
                                loop={false}
                                margin={20}
                                nav
                                items={6}
                                navText={['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>']}
                            >
                                {
                                    icon && icon.map((icon, index) => {
                                        return (
                                            <div class="item"><button onClick={(e) => {
                                                imagecheck("icon", index + 1)
                                                avtaractive("avtar" + index + 1)
                                            }} id={"avtar" + index + 1}><img src={icon.icon} alt="" /></button><span>{index + 1}</span></div>
                                        )
                                    })
                                }
                            </OwlCarousel>
                            {/* <button onClick={(e) => viewmore()} class="view">{shoavatar >= 20 ? "Less Avatar" : "View More"}</button> */}
                        </div>
                        <div class="left">
                            <label>Enter Number : </label>
                            <input type="test" onChange={(e) => imagecheck("icon", e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid " id="launchpad">
                <div class="container">
                    <div className="launched">
                        <Link to="/"><i class="fa fa-arrow-circle-left" aria-hidden="true"></i> PATIENT CONSOLE</Link>
                        <form action="">
                            <label for="">Child Mode </label>
                            <label class="switch">
                                <label>OFF</label>
                                <input type="checkbox" onClick={(e) => checkbox(e)} name="sound" id="checkbox" />
                                <span class="slider round"></span>
                                <label>ON</label>
                            </label>
                        </form>

                        <div className="full">
                            <div className="reset">
                                <p>Duration of today's scans</p>
                                <button id="mint12" onClick={(e) => {
                                    setminutes(0)
                                    window.location.reload(false)
                                }}>Reset</button>
                            </div>

                            <div className="button">
                                <ul>
                                    <li><button id="mint1" onClick={(e) => {
                                        setminutes(5)
                                        classactive("mint1")
                                    }}>5 Min</button></li>
                                    <li><button id="mint2" onClick={(e) => {
                                        setminutes(15)
                                        classactive("mint2")
                                    }}>15 Min</button></li>
                                    <li><button id="mint3" onClick={(e) => {
                                        setminutes(25)
                                        classactive("mint3")
                                    }}>25 Min</button></li>
                                    <li><button id="mint4" onClick={(e) => {
                                        setminutes(10)
                                        classactive("mint4")
                                    }}>10 Min</button></li>
                                    <li><button id="mint5" onClick={(e) => {
                                        setminutes(20)
                                        classactive("mint5")
                                    }}>20 Min</button></li>
                                    <li><button id="mint6" onClick={(e) => {
                                        setminutes(30)
                                        classactive("mint6")
                                    }}>30 Min</button></li>
                                    <li><button id="mint7" onClick={(e) => {
                                        setminutes(45)
                                        classactive("mint7")
                                    }}>45 Min</button></li>
                                    <li><button id="mint8" onClick={(e) => {
                                        setminutes(60)
                                        classactive("mint8")
                                    }}>60 Min</button></li>
                                    <li><button id="mint9" onClick={(e) => {
                                        setminutes(100)
                                        classactive("mint9")
                                    }}>60+</button></li>
                                </ul>

                            </div>
                        </div>

                    </div>
                    <div class="midd">
                        <ul>
                            <li>
                                <p>{progress ? (progress < 2 || minutes == 0 ? "Stop in 2 minutes" : "In Progress") : "Launch Game"}</p>
                                <p>Currently: <strong>{current != '' ? current : "Puppy Dreams"}</strong></p>
                                <p>Up Next : <strong>{status != '' ? status : " Horse Dreams"}</strong></p>
                                <p><span style={seconds == 0 && minutes == 0 ? { color: 'green' } : {}}>Stops in   {minutes > 0 ? minutes : "00"}:{seconds > 0 ? seconds : "00"}</span></p>
                            </li>
                            <li><button onClick={(e) => checke()}>START</button></li>
                            <li><button ><a href="https://www.youtube.com/" target="_blank">Launch YouTube</a></button></li>
                        </ul>
                    </div>
                    <div className="down">
                        <h4>Patient Narrative : (optional) </h4>
                        <p>"OK get ready for a mission, you are doing Great! This is Puppy Dreams... </p>
                        <p>" HOLD STILL AND KEEP PUPPY SLEEPING"</p>
                        <p>We will take a break when you're done... </p>
                        <p>END: " WOW, that was awesome!! You are good at this. Amazing. Better than most adults! "</p>
                    </div>
                </div>
            </div>
        </>
    )
}